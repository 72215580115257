import React, {Component, Fragment} from 'react';
import './modal-wrapper.scss';
import ModalWrapper from "./ModalWrapper";
import ProjectSelector from "../Project/ProjectSelector";
import './modal-add-to-project-bulk.scss';
import {AppContext} from "../../context/AppProvider";
import NotificationWrapper from "../_wrappers/NotificationWrapper";

export default class ModalAddToProjectBulk extends Component {
    static defaultProps = {
        isOpen: false,
        onClose: () => {
        },
        onUpdate: () => {
        },
        profiles: [],
        filters: [],
    };

    static contextType = AppContext;

    state = {
        selectedProject: null,
        sending: false,
    };

    showSuccessNotification = () => {
        const profileText = this.props.profiles.length === 1 ? "1 perfil añadido" : `${this.props.profiles.length} perfiles añadidos`;
        this.notification.board.addNotification({
            title: '¡Todo bien!',
            content: `${profileText} al proyecto ${this.state.selectedProject.name}`,
            className: 'success'
        });
        this.setState({sending: false});
        this.props.onClose();
    }

    showErrorNotification = (reason) => {
        this.setState({sending: false});
        this.notification.board.addNotification({
            title: 'Error al asignar perfiles a proyecto',
            content: reason.message,
            className: 'danger'
        });
    }

    onAccept = () => {
        this.setState({sending: true});
        if (this.props.profiles?.length > 0) {
            this.context.services.project
                .addProfiles(this.state.selectedProject.idProject, this.props.profiles.map(profile => profile.user.idUser))
                .then(response => this.showSuccessNotification(response))
                .catch(reason => this.showErrorNotification(reason));
        } else {
            this.context.services.project
                .addFilteredProfiles(this.state.selectedProject.idProject, this.props.filters)
                .then(response => this.showSuccessNotification(response))
                .catch(reason => this.showErrorNotification(reason));
        }
    }

    render() {
        return (
            <Fragment>
                <ModalWrapper title="Asociar perfiles a proyecto"
                              open={this.props.isOpen}
                              canAccept={true}
                              isDisabled={!this.state.selectedProject || this.state.sending}
                              sending={this.state.sending}
                              onAccept={this.onAccept}
                              onClose={this.props.onClose}>
                    <div className="modal-add-to-project-bulk">
                        {this.props.profiles.length > 0
                            ?
                            <p>Solo se asociarán los perfiles seleccionados
                                ({this.props.profiles.length === 1 ? "1 perfil"
                                    : `${this.props.profiles.length} perfiles`})</p>
                            :
                            <p>Se asociarán todos los perfiles filtrados</p>
                        }

                        <ProjectSelector placeholder='Busca y selecciona el proyecto para asociarlo a los perfiles…'
                                         profiles={this.props.profiles}
                                         onSelectProject={project => this.setState({selectedProject: project})}
                        />
                    </div>

                </ModalWrapper>
                <NotificationWrapper ref={ref => this.notification = ref}/>
            </Fragment>
        );
    }
}